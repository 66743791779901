
<template>
	<div style="padding: 10px">
		<md-card class="md-card-timeline" :md-with-hover="true" v-for="item in data" :key="item.id" v-if="data.length > 0">
			<div class="md-subhead" style="padding-bottom: 5px">
				<md-icon>schedule</md-icon>
				<span> {{item.fecha}} </span>
			</div>
			<md-card-content>
				{{item.text}}
			</md-card-content>
			<div class="md-foot">
				<md-chip>
					<span style="display: flex; align-items: center">
						<md-icon>face</md-icon> <span style="margin-left: 3px">{{item.nombre_completo}}</span>
					</span>
				</md-chip>
			</div>
		</md-card>

		<md-empty-state v-if="data.length  == 0" md-icon="access_time" :md-label="$store.state.lang.sin_registros">
		</md-empty-state>

	</div>
</template>
<script>
	import {request} from '../../../helpers';

	export default {

		methods:{
			async request(){
				let id = this.$route.params.id;
				this.$store.state.loading = true;
				let r = await request(`${base_url}/api/historial/${id}?jwt=${this.$store.state.jwt}`);

				if(r.ok){
					this.data = r.r.data;
				}

				this.$store.state.loading = false;
			}
		},
		mounted() {
			this.request();
		},
		data(){
			return {
				data :[]
			}
		}

	}

</script>
<style type="scss" scoped>
	.md-card-timeline{
		margin-bottom: 10px;
	}
	.md-subhead{
		padding: 7px 7px;
		border-bottom: 1px solid #b5b5b5;
	}
	.md-foot{
		padding: 7px 7px 10px;
		text-align: right;
	}
</style>
